import { useDispatch } from "react-redux";
import { formatDate } from "../../utils/dateHelper";
import { setEditMode } from "../../features/ra/raSlice";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const RaSalaryList = ({ list }) => {
  return (
    <div>
      <table className="table table-hover">
        <thead>
          <tr>
            <th>Sl#</th>
            <th>RA</th>
            <th>Project Name</th>

            <th>Bank Ac</th>
            <th>
              <small>
                Joining <br />
                Termination
              </small>
            </th>
            <th>Month, Year</th>
            <th>Monthly Salary</th>
            <th>Salary Including Tax</th>
            <th>TAX Deduction (%)</th>
            <th>Salary Payable</th>
            <th>Comment</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => {
            let counter = 0;
            return (
              <tr key={item._id}>
                <td>{(counter += 1)}</td>
                <td align="center">
                  <small>{item?.raProjectAssign?.raPersonnel?.name}</small>
                </td>
                <td align="center">{item?.raProjectAssign?.project?.title}</td>

                <td align="center">
                  <small>{item?.raProjectAssign?.raPersonnel?.bankAc}</small>
                </td>
                <td align="center">
                  <small>
                    {formatDate(item?.raProjectAssign?.raPersonnel?.dateJoined)}
                    -{" "}
                    {formatDate(
                      item?.raProjectAssign?.raPersonnel?.dateTermination
                    )}
                  </small>
                </td>
                <td align="center">
                  <small>
                    {item?.financialMonth}, {item?.financialYear}
                  </small>
                </td>
                <td align="center">
                  {item?.raProjectAssign?.raPersonnel?.monthlySalary}
                </td>
                <td align="center">{item?.salaryIncludingTax}</td>
                <td align="center">{item.itDeductionRate} %</td>
                <td align="center">{item.netPayable}</td>
                <td align="center">{item.comment}</td>
                <td align="center"></td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};
export default RaSalaryList;
