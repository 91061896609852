import { useEffect } from "react";
import { StatsContainer, Loading, ChartsContainer } from "./../../components";
import { useDispatch, useSelector } from "react-redux";
import { showStats } from "../../features/allProjects/allProjectsSlice";

const Stats = () => {
  const { isLoading, monthlyApplications } = useSelector(
    (store) => store.allProjects
  );
  console.log(monthlyApplications);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(showStats());
  }, []);
  if (isLoading) {
    return <Loading center />;
  }

  return (
    <>
      <StatsContainer />
      {monthlyApplications.length > 0 && <ChartsContainer />}
    </>
  );
};
export default Stats;
