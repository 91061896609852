import ProjectContainer from "../../components/project/ProjectContainer";

const AllProject = () => {
  return (
    <>
      <ProjectContainer />
    </>
  );
};
export default AllProject;
