import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const ButtonStyled = ({
  theme = "1",
  type = "button",
  linkAddress = "",
  text,
  btnfunction,
  disabled,
}) => {
  const buttonTheme =
    theme === "1"
      ? "primary"
      : theme === "2"
      ? "success"
      : theme === "3"
      ? "secondary"
      : "error";

  if (btnfunction) {
    return (
      <Button
        disabled={disabled}
        variant="contained"
        color={buttonTheme}
        type={type}
        component={Link}
        to={linkAddress}
        onClick={btnfunction}
      >
        {text}
      </Button>
    );
  }
  return (
    <Button
      disabled={disabled}
      type={type}
      variant="contained"
      color={buttonTheme}
      component={Link}
      to={linkAddress}
    >
      {text}
    </Button>
  );
};
export default ButtonStyled;
