import { FormRowSelect } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import { useSelector, useDispatch } from "react-redux";
import {
  clearValues,
  handleChange,
  createRaToProject,
  editRaToProject,
} from "../../features/raToProject/raToProjectSlice";
import { getAllProjects } from "../../features/allProjects/allProjectsSlice";
import { useEffect } from "react";
import FormRowSelectObj from "../../components/FormRowSelectObj";
import { getAllRa } from "../../features/allRa/allRaSlice";


const AddRaToProject = () => {
  const dispatch = useDispatch();
  const {
    isLoading,
    project,
    raPersonnel,
    isEditing,
    editObjId,
  } = useSelector((store) => store.raToProject);

  const { list: projectList } = useSelector((store) => store.allProjects);
  const { list: raList } = useSelector((store) => store.allRa);
  const raToProjectObj = {
    project,
    raPersonnel,
  };

  useEffect(()=>{
    dispatch(getAllProjects())
    dispatch(getAllRa())

    console.log(projectList);
  }, [])

  const handleObjectInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isEditing) {
      dispatch(
        editRaToProject({
          id: editObjId,
          obj: raToProjectObj,
        })
      );
      return;
    }
    dispatch(createRaToProject(raToProjectObj));
  };

    return (
      <Wrapper>
        <form className="form">
          <h3>{isEditing ? "edit ": "assign "}RA to project</h3>
          <div className="form-center">
            <FormRowSelectObj
              name="project"
              value={project}
              handleChange={handleObjectInput}
              list={projectList}
            />
            <FormRowSelectObj
              name="raPersonnel"
              value={raPersonnel}
              labelText="project status"
              handleChange={handleObjectInput}
              list={raList}
            />

            <div className="btn-container">
              <button
                type="button"
                className="btn btn-block clear-btn"
                onClick={() => dispatch(clearValues())}
              >
                Clear
              </button>
              <button
                type="submit"
                className="btn btn-block smit-btn"
                onClick={handleSubmit}
                disabled={isLoading}
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </Wrapper>
    );
};
export default AddRaToProject;
