import { showLoading, hideLoading, getAllRa } from "../allRa/allRaSlice";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { clearValues } from "./raSalarySlice";

const url = "/raSalaries";

export const createRaSalaryThunk = async (ra, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, ra);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteRaSalaryThunk = async (_id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`${url}/${_id}`);
    thunkAPI.dispatch(getAllRa());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const editRaSalaryThunk = async ({ _id, ra }, thunkAPI) => {
  console.log(_id, ra);
  try {
    const resp = await customFetch.patch(`${url}/${_id}`, ra);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
