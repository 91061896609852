import ProductContainer from "../../components/ProductContainer";


export const ProductView = () => {


return(
    <>
    <ProductContainer />
    </>
)
};
