const FormRowSelectObj = ({ labelText, name, value, handleChange, list }) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={handleChange}
        className="form-select"
      >
        {list?.map((itemValue) => {
          return (
            <option key={itemValue._id} value={itemValue._id}>
              {itemValue.title || itemValue.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default FormRowSelectObj;
