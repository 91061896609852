import Wrapper from "../../assets/wrappers/JobsContainer";
import RaToProjectList from "./RaToProjectList";
import AddRaToProject from "../../pages/dashboard/AddRaToProject";
import Paper from "@mui/material/Paper";

const RaToProjectContainer = () => {
  return (
    <Wrapper>
      <Paper style={{ padding: "10px" }}>
        <RaToProjectList />
      </Paper>

      <AddRaToProject />
    </Wrapper>
  );
};

export default RaToProjectContainer;
