import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCurrentDateString } from "../../utils/dateHelper";
import { createRaThunk, deleteRaThunk, editRaThunk } from "./raThunk";

const initialState = {
  bankAc: 0,
  monthlySalary: 0,
  defaultDate: getCurrentDateString(),
  categoryOtions: ["Internal", "External"],
  category: "Internal",
  designationOptions: ["Current", "Former"],
  designation: "Current",
  project: "647d882bf391bb1b56247026",
  _id: "",
  name: "",
  dateJoined: getCurrentDateString(),
  dateTermination: getCurrentDateString(),
};
export const createRa = createAsyncThunk("ra/createRa", createRaThunk);

export const deleteRa = createAsyncThunk("ra/deleteRa", deleteRaThunk);

export const editRa = createAsyncThunk("ra/editRa", editRaThunk);

const raSlice = createSlice({
  name: "ra",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    // aita add koro nai
    handleDate: (state, { payload: { name, value } }) => {
      state[name] = new Date(value).toISOString();
    },
    clearValues: () => {
      //return initialState;
      return {
        ...initialState,
      };
    },
    setEditMode: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRa.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRa.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Ra Created");
      })
      .addCase(createRa.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteRa.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteRa.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(editRa.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editRa.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Ra Modified...");
      })
      .addCase(editRa.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { handleChange, clearValues, setEditMode, handleDate } =
  raSlice.actions;
export default raSlice.reducer;
