import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCurrentDateString } from "../../utils/dateHelper";
import { createFundThunk, deleteFundThunk, editFundThunk } from "./fundsThunk";

const mathRounder = (num) => Number((Math.round(num * 100) / 100).toFixed(2));

const initialState = {
  isLoading: false,
  isEditing: false,
  editObjId: "",
  defaultDate: getCurrentDateString(),
  project: "",
  chequeNumber: 0,
  fundReceived: 0,
  overheadAmount: 0,
  riseAmount: 0,
  piAmount: 0,
  departmentAmount: 0,
  projectAmount: 0,
  fundReceivedDate: getCurrentDateString(),
};

export const createFund = createAsyncThunk(
  "fund/createProject",
  createFundThunk
);
export const deleteFund = createAsyncThunk("fund/deletefund", deleteFundThunk);
export const editFund = createAsyncThunk("fund/editfund", editFundThunk);

const fundSlice = createSlice({
  name: "fund",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    calculateAmount: (state, { payload }) => {
      state.overheadAmount = mathRounder((payload * 15) / 100);
      state.riseAmount = mathRounder((payload * 10) / 100);
      state.piAmount = mathRounder((payload * 3) / 100);
      state.departmentAmount = mathRounder((payload * 2) / 100);
      state.projectAmount = mathRounder((payload * 85) / 100);
    },
    handleDate: (state, { payload: { name, value } }) => {
      state[name] = new Date(value).toISOString();
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    setEditMode: (state, { payload }) => {
      console.log(payload);
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createFund.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createFund.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Fund Added");
      })
      .addCase(createFund.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteFund.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteFund.rejected, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(editFund.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editFund.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Fund Updated...");
      })
      .addCase(editFund.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});
export const {
  handleChange,
  handleDate,
  calculateAmount,
  clearValues,
  setEditMode,
} = fundSlice.actions;
export default fundSlice.reducer;
