import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { clearValues } from "./raToProjectSlice";
import {
  showLoading,
  hideLoading,
  getAllRaToProject,
} from "./raToProjectSlice";
const url = "/raProjectAssign"
export const getAllRaToProjectThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get(url);
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const createRaToProjectThunk = async (raToProject, thunkAPI) => {
  try {
    const resp = await customFetch.post(url, raToProject);
    thunkAPI.dispatch(getAllRaToProject());
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const editRaToProjectThunk = async ({ _id, raToProject }, thunkAPI) => {
  console.log(_id, raToProject);
  try {
    const resp = await customFetch.patch(`${url}/${_id}`, raToProject);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
export const deleteRaToProjectThunk = async (id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`${url}/${id}`);
    thunkAPI.dispatch(getAllRaToProject());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
