import { Link } from "react-router-dom";
import main from "../assets/images/main.svg";
import Wrapper from "../assets/wrappers/LandingPage";
import { Logo } from "../components";


const Landing = () => {
  return (
    <Wrapper>
      <nav>
        <Logo />
      </nav>
      <div className="container page">
        <div className="info">
          <h1>
            RISE <span>Office</span> app
          </h1>
          <p>
            Lorem, ipsum dolor sit amet consectetur adipisicing elit. In placeat
            necessitatibus dolorem aperiam illo quidem, consequuntur, saepe
            vitae itaque molestiae fugit dolor rem doloremque, eum quis eius
            modi iure autem.
          </p>
          <Link to='/register' className="btn btn-hero">Login/register</Link>
        </div>
        <img src={main} alt="job hunt" className="img main-img" />
      </div>
    </Wrapper>
  );
};

export default Landing;
