import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FormRow } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {
  clearValues,
  handleChange,
  createUser,
  editUser,
} from "../../features/userCreate/userCreateSlice";
import FormRowCheckbox from "../../components/FormRowCheckbox";

const AddFunds = () => {
  const {
    isLoading,
    isEditing,
    editObjId,
    _id,
    name,
    email,
    password,
    isVerified,
  } = useSelector((store) => store.userCreate);

  const userObj = {
    _id,
    name,
    email,
    password,
    isVerified,
  };
  const dispatch = useDispatch();

  const handleObjectInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };
    const handleCheckbox = (e) => {
      const name = e.target.name;
      const value = e.target.checked;
      dispatch(handleChange({ name, value }));
    };


  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email) {
      toast.error("Please fill out all fields");
      return;
    }
    if (isEditing) {
      dispatch(editUser(userObj));
      return;
    }
    dispatch(createUser(userObj));
  };

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? "edit User" : "add User"}</h3>

        <div className="form-center">
          <FormRow
            type="text"
            name="name"
            value={name}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="email"
            name="email"
            value={email}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="text"
            name="password"
            value={password}
            handleChange={handleObjectInput}
          />
          {/* <FormRowCheckbox
            labelText="is verfied"
            name="isVerified"
            checked={isVerified}
            handleChange={handleCheckbox}
          /> */}
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-block smit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
export default AddFunds;
