import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

import {
  createRaSalaryThunk,
  editRaSalaryThunk,
  deleteRaSalaryThunk,
} from "./raSalaryThunk";

const month = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const d = new Date();
let currentMonth = month[d.getMonth()];

let year = d.getFullYear();

const initialState = {
  isLoading: false,
  isEditing: false,
  editObjId: "",
  raProjectAssign: "",
  salaryIncludingTax: 0,
  salary: 0,
  itDeduction: 0,
  financialMonth: currentMonth,
  financialMonthOptions: month,
  financialYear: year,
  netPayable: 0,
  totalSalary: 0,
  bankAc: "",
  comment: "",
};

export const createRaSalary = createAsyncThunk(
  "raSalary/createRaSalary",
  createRaSalaryThunk
);

export const deleteRaSalary = createAsyncThunk(
  "raSalary/deleteRaSalary",
  deleteRaSalaryThunk
);

export const editRaSalary = createAsyncThunk(
  "raSalary/editRaSalary",
  editRaSalaryThunk
);

const raSalarySlice = createSlice({
  name: "raSalary",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    clearValues: () => {
      //return initialState;
      return {
        ...initialState,
      };
    },
    setEditMode: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
    populateSalary: (state, { payload }) => {
      console.log(payload);
      state.salary = payload.monthlySalary;
      state.netPayable = payload.monthlySalary;
      state.totalSalary = payload.monthlySalary;
      state.bankAc = payload.bankAc;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createRaSalary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRaSalary.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("RA salary added");
      })
      .addCase(createRaSalary.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteRaSalary.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteRaSalary.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(editRaSalary.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editRaSalary.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("RA Salary Modified...");
      })
      .addCase(editRaSalary.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const {
  handleChange,
  clearValues,
  setEditMode,
  handleDate,
  populateSalary,
} = raSalarySlice.actions;
export default raSalarySlice.reducer;
