import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { FaTrash } from "react-icons/fa";
import { useDispatch } from "react-redux";
import {
  setEditMode,
  deleteUser,
  updateVerification,
} from "../../features/userCreate/userCreateSlice";


const User = (row) => {
  const { _id, name, email, role, isVerified, counter } = row;

  const dispatch = useDispatch();
  let number = 0;

  const handleCheckbox = (e) => {
    console.log(e);

    const name = e.target.name;
    const value = e.target.checked;
    console.log(value);
    dispatch(updateVerification({ ...row, [name]: value }));

  };

  return (
    <>
      <TableRow
        key={_id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell> {counter}. </TableCell>
        <TableCell align="right">{name}</TableCell>
        <TableCell align="right">{email}</TableCell>
        <TableCell align="right">{role}</TableCell>
        <TableCell align="right">
          <Checkbox
            name="isVerified"
            checked={isVerified}
            onChange={handleCheckbox}
          />

        </TableCell>

        <TableCell align="center">
          <ButtonGroup
            variant="contained"
            aria-label="outlined primary button group"
          >
            <Button
              component={Link}
              to="/add-user"
              onClick={() =>
                dispatch(setEditMode({ ...row, editObjId: row._id }))
              }
            >
              Edit
            </Button>
            <Button onClick={() => dispatch(deleteUser(row._id))} color="error">
              <FaTrash />
            </Button>
          </ButtonGroup>
        </TableCell>
      </TableRow>
    </>
  );
};
export default User;
