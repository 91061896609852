import axios from "axios";
import { getUserFromLocalStorage } from "./localStorage";
import { clearStore } from "../features/user/userSlice";

const customFetch = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL+ "/v1",
  withCredentials: true,
});

customFetch.interceptors.request.use((config) => {
  const user = getUserFromLocalStorage();
  if (user) {
    config.headers["Authorization"] = `Bearer ${user.token}`;
  }
  return config;
});

export const checkForUnauthorizedResponse = (error, thunkAPI) => {
  if (error.response.status === 401) {
    thunkAPI.dispatch(clearStore());
    return thunkAPI.rejectWithValue("Unauthorized, Logging out...");
  }
  return thunkAPI.rejectWithValue(error.response.data.msg);
};
export const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL+"/v1",
  withCredentials: true,
});

export default customFetch;
