import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { formatDate } from "../../utils/dateHelper";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { FaTrash } from "react-icons/fa";
import { deleteFund, setEditMode } from "../../features/funds/fundsSlice";

const FundsGrouped = ({ list }) => {

    const dispatch = useDispatch();
  return (
    <>
      <Typography variant="h6" gutterBottom component="div">
        Fund{list.length > 1 && "s"}
      </Typography>
      <Table size="small" aria-label="funds">
        <TableHead>
          <TableRow>
            <TableCell align="center">Receive Date</TableCell>
            <TableCell align="center">Cheque No</TableCell>
            <TableCell align="center">Amount Received</TableCell>
            <TableCell align="center">Overhead</TableCell>
            <TableCell align="center">RISE</TableCell>
            <TableCell align="center">PI</TableCell>
            <TableCell align="center">Department</TableCell>
            <TableCell align="center">Project</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => {
            const id = row._id;
            return (
              <TableRow
                key={id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell align="center">
                  {formatDate(row.fundReceivedDate)}
                </TableCell>
                <TableCell align="center">{row.chequeNumber}</TableCell>
                <TableCell align="center">
                  {currencyFormatter(row.fundReceived)}
                </TableCell>
                <TableCell align="center">
                  {currencyFormatter(row.overheadAmount)}
                </TableCell>
                <TableCell align="center">
                  {currencyFormatter(row.riseAmount)}
                </TableCell>
                <TableCell align="center">
                  {currencyFormatter(row.piAmount)}
                </TableCell>
                <TableCell align="center">
                  {currencyFormatter(row.departmentAmount)}
                </TableCell>
                <TableCell align="center">
                  {currencyFormatter(row.projectAmount)}
                </TableCell>
                <TableCell align="center">
                  <ButtonGroup
                    variant="contained"
                    aria-label="outlined primary button group"
                  >
                    <Button
                      component={Link}
                      to="/add-funds"
                      onClick={() =>
                        dispatch(setEditMode({ ...row, editObjId: row._id }))
                      }
                    >
                      Edit
                    </Button>

                    <Button
                      onClick={() => dispatch(deleteFund(row._id))}
                      color="error"
                    >
                      <FaTrash />
                    </Button>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </>
  );
};
export default FundsGrouped;
