import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAllRaSalary } from "../../features/allRaSalary/allRaSalarySlice";
import Wrapper from "../../assets/wrappers/JobsContainer";
import Loading from "../Loading";
import RaSalaryList from "./RaSalaryList";
import Paper from "@mui/material/Paper";
import ButtonStyled from "../ButtonStyled";

const RaSalaryContainer = () => {
  const dispatch = useDispatch();
  const { list, isLoading, count } = useSelector((store) => store.allRaSalary);

  useEffect(() => {
    dispatch(getAllRaSalary());
    console.log(list);
  }, []);

  const showMe = () => {
    console.log("button clicked");
  };

  if (isLoading) {
    return <Loading center />;
  }
  return (
    <Wrapper>
      <h3>
        Salary Sheet({count}){" "}
        <ButtonStyled
          linkAddress="/add-raSalary"
          text="Print"
          theme="1"
          btnfunction={showMe}
        />
      </h3>
      <Paper elevation={3}>
        <RaSalaryList list={list} />
      </Paper>
    </Wrapper>
  );
};
export default RaSalaryContainer;
