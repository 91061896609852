import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { handleChange } from "../../features/allUser/allUserSlice";
import User from './User';

const UserList = ({ list }) => {
  const dispatch = useDispatch();
  let number = 0;

 const handleCheckbox = (e) => {
   const name = e.target.name;
   const value = e.target.checked;
   console.log(value);
   dispatch(handleChange({ name, value }));

 };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>#</TableCell>
            <TableCell align="right">Name</TableCell>
            <TableCell align="right">Email</TableCell>
            <TableCell align="right">Role</TableCell>
            <TableCell align="right"></TableCell>

            <TableCell align="right"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row, index) => {
            let counter =index+1 ;
            let newList = { ...row, counter};
            return (
             <User {...newList } key={row._id} />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
export default UserList;
