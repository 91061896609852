import { FormRow, FormRowSelect } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  clearValues,
  handleChange,
  handleDate,
  createProject,
  editProject,
} from "../../features/project/projectSlice";

import FormRowDate from "../../components/FormRowDate";
import DepartmentsOption from "../../components/DepartmentsOption";

const AddProject = () => {
  const {
    isLoading,
    defaultDate,
    title,
    category,
    categoryOptions,
    description,
    deptInstitute,
    piName,
    coPiName,
    startingDate,
    endingDate,
    projectStatus,
    projectStatusOptions,
    riseFileTrakerNumber,
    isEditing,
    editObjId,
  } = useSelector((store) => store.project);

  const projectObj = {
    title,
    category,
    description,
    deptInstitute,
    piName,
    coPiName,
    startingDate,
    endingDate,
    projectStatus,
    riseFileTrakerNumber,
  };
  const dispatch = useDispatch();

  const handleObjectInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };
  const handleDateInput = (name, value) => {
    let modDate = value ? Date.parse(value) : defaultDate;
    dispatch(handleDate({ name, value: modDate }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!title || !piName || !coPiName) {
      toast.error("Please fill out all fields");
      return;
    }
    if (isEditing) {
      dispatch(
        editProject({
          id: editObjId,
          obj: projectObj,
        })
      );
      return;
    }
    dispatch(createProject(projectObj));
  };

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? "edit project" : "add project"}</h3>
        <div className="form-center">
          <FormRow
            type="text"
            name="title"
            value={title}
            handleChange={handleObjectInput}
          />
          <DepartmentsOption
            name="deptInstitute"
            value={deptInstitute._id}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="text"
            name="piName"
            labelText="pi Name"
            value={piName}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="text"
            name="coPiName"
            labelText="co-Pi Name"
            value={coPiName}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="text"
            name="description"
            value={description}
            handleChange={handleObjectInput}
          />

          <FormRowSelect
            name="category"
            value={category}
            handleChange={handleObjectInput}
            list={categoryOptions}
          />
          <FormRowSelect
            name="projectStatus"
            value={projectStatus}
            labelText="project status"
            handleChange={handleObjectInput}
            list={projectStatusOptions}
          />
          <FormRow
            type="text"
            name="riseFileTrakerNumber"
            labelText="rise Filetraker Number"
            value={riseFileTrakerNumber}
            handleChange={handleObjectInput}
          />
          <FormRowDate
            name="startingDate"
            selected={defaultDate}
            value={startingDate}
            handleDate={handleDateInput}
          />
          <FormRowDate
            name="endingDate"
            selected={defaultDate}
            value={endingDate}
            handleDate={handleDateInput}
          />
          {/* <DatePicker
            selected={endingDate}
            closeCalendar={false}
            value={endingDate}
            onChange={(endingDate) => handleDateInput("endingDate", endingDate)}
          /> */}
          {/* <DatePicker
            name="startingDate"
            value={startingDate}
            closeCalendar={false}
            onChange={(startingDate) => {
              dispatch(handleDate(startingDate));
            }}
          /> */}
          {/* <DatePicker
            selected={startDate}
            value={startingDate}
            name="startingDate"
            onChange={handleObjectInput}
          >
            <div style={{ color: "red" }}>
              Don't forget to check the weather!
            </div>
          </DatePicker> */}

          <div className="btn-container">
            <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-block smit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
export default AddProject;
