import { IoBarChartSharp } from "react-icons/io5";
import { MdQueryStats } from "react-icons/md";
import { FaWpforms } from "react-icons/fa";
import { FaCoins } from "react-icons/fa";
import { ImProfile } from "react-icons/im";
import { BsFillPersonPlusFill } from "react-icons/bs";
import { BsFillPeopleFill } from "react-icons/bs";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";

const links = [
  { id: 1, text: "stat", path: "/", icon: <IoBarChartSharp /> },
  {
    id: 2,
    text: "users",
    path: "all-user",
    icon: <BsFillPersonPlusFill />,
  },
  { id: 3, text: "projects", path: "all-projects", icon: <MdQueryStats /> },
  {
    id: 4,
    text: "funds",
    path: "all-funds",
    icon: <AiOutlineFundProjectionScreen />,
  },
  { id: 5, text: "RA", path: "all-ra", icon: <BsFillPeopleFill /> },
  {
    id: 6,
    text: "Assign RA To Project",
    path: "add-raToProject",
    icon: <BsFillPersonPlusFill />,
  },
  { id: 7, text: "RA Salary", path: "all-raSalary", icon: <FaCoins /> },
  // { id: 9, text: "all jobs", path: "all-jobs", icon: <MdQueryStats /> },
  // { id: 10, text: "add job", path: "add-job", icon: <FaWpforms /> },
  // { id: 11, text: "profile", path: "profile", icon: <ImProfile /> },
];
export default links;
