import Wrapper from "../../assets/wrappers/JobsContainer";
import { useEffect } from "react";
import Loading from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import {
  getAllProjectsWithFunds,
} from "../../features/allFunds/allFundsSlice";
import ProjectList from "./ProjectList";

const FundsContainer = () => {
  const dispatch = useDispatch();
  const { list, count, isLoading } = useSelector((store) => store.allFunds);

  useEffect(() => {
    dispatch(getAllProjectsWithFunds());
  }, []);


   if (isLoading) {
     return <Loading center />;
   }

  return (
    <Wrapper>
      <h3>Fund Index</h3>
      {/* <h3>
        {count} fund{count && "s"}
      </h3> */}

      <ProjectList list={list} />
    </Wrapper>
  );
};
export default FundsContainer;
