import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { clearValues } from "./projectSlice";
import { showLoading, hideLoading, getAllProjects } from "../allProjects/allProjectsSlice";



export const createProjectThunk = async (obj, thunkAPI) => {
  try {
    const resp = await customFetch.post("/projects", obj);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteProjectThunk = async (id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/projects/${id}`);
    thunkAPI.dispatch(getAllProjects());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const editProjectThunk = async ({ id, obj }, thunkAPI) => {
  try {
    console.log(id, obj);
    const resp = await customFetch.patch(`/projects/${id}`, obj);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
