import img from '../assets/images/logo.svg'
import img2 from '../assets/images/riseLogo.png'
import img3 from '../assets/images/riseColor.svg'




const Logo = () => {
  return <img src={img3} alt="RISE" className="logo" />;
}
export default Logo