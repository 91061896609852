import { useDispatch, useSelector } from "react-redux";
import { getAllUsers } from "../../features/allUser/allUserSlice";
import Wrapper from "../../assets/wrappers/JobsContainer";
import { useEffect } from "react";
import Loading from "../Loading";
import UserList from "./UserList";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";


const UserContainer = () => {
  const dispatch = useDispatch();
  const { isLoading, list, count } = useSelector((store) => store.allUser);

  useEffect(() => {
    dispatch(getAllUsers());
  }, []);

  if (isLoading) {
    return <Loading center />;
  }
  if (list.length) {
    return (
      <Wrapper>
        <h3>
          {count} User{count && "s "}
          <Button variant="contained" component={Link} to="/add-user">
            Add User
          </Button>
        </h3>
        <UserList list={list} />
        {/* <TableDisplay rows={list} /> */}
      </Wrapper>
    );
  }
};
export default UserContainer;
