import StatItem from "./StatItem";
import { FaSuitcaseRolling, FaCalendarCheck, FaBug } from "react-icons/fa";
import Wrapper from "../assets/wrappers/StatsContainer";
import { useSelector } from "react-redux";

const StatsContainer = () => {
  const {stats} = useSelector((store)=>store.allProjects);

  const defaultStats = [
    {
      title: "Internal",
      count: stats.internal || 0,
      icon: <FaSuitcaseRolling />,
      color: "#e9b949",
      bcg: "#fcefc7",
    },
    {
      title: "External",
      count: stats.external || 0,
      icon: <FaCalendarCheck />,
      color: "#647acb",
      bcg: "#e0e8f9",
    },
    {
      title: "MoU",
      count: stats.mou || 0,
      icon: <FaBug />,
      color: "#d66a6a",
      bcg: "#ffeeee",
    },
  ];

  return <Wrapper>
    {defaultStats.map((item, index)=> {
      return <StatItem key={index} {...item} />;
    })}
  </Wrapper>;
};
export default StatsContainer;
