import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useDispatch } from "react-redux";
import { formatDate } from "../../utils/dateHelper";
import {
  deleteProject,
  setEditMode,
} from "../../features/project/projectSlice";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { FaTrash } from "react-icons/fa";

const ProjectSec = ({ projectList }) => {
  let counter = 0;
  const dispatch = useDispatch();
  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>#</TableCell>
              <TableCell align="center">Project Name</TableCell>
              <TableCell align="center">Dept</TableCell>
              <TableCell align="center">Category</TableCell>
              <TableCell align="center">Co Pi</TableCell>
              <TableCell align="center">
                Project <br /> <small>start - end</small>
              </TableCell>
              <TableCell align="center">Finance</TableCell>
              <TableCell align="center">Options</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projectList.map((row) => {
              const id = row._id;
              return (
                <TableRow
                  key={id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {(counter += 1)}
                  </TableCell>
                  <TableCell align="center">{row.title}</TableCell>
                  <TableCell align="center">{row.deptInstitute.name}</TableCell>
                  <TableCell align="center">{row.category}</TableCell>
                  <TableCell align="center">{row.coPiName}</TableCell>
                  <TableCell align="center">
                    {formatDate(row.startingDate)} <br />
                    {formatDate(row.endingDate)}
                  </TableCell>
                  <TableCell align="center">
                    Total Received: <b style={{color: 'green'}}> {row.totalReceived}</b>
                  </TableCell>
                  <TableCell align="center">
                    <ButtonGroup
                      variant="contained"
                      aria-label="outlined primary button group"
                    >
                      <Button component={Link} to="/add-funds" state={row}>
                        Add fund
                      </Button>
                      <Button
                        component={Link}
                        to="/add-project"
                        onClick={() =>
                          dispatch(setEditMode({ ...row, editObjId: row._id }))
                        }
                      >
                        Edit
                      </Button>
                      <Button
                        onClick={() => dispatch(deleteProject(row._id))}
                        color="error"
                      >
                        <FaTrash />
                      </Button>
                    </ButtonGroup>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};
export default ProjectSec;
