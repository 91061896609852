import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCurrentDateString } from "../../utils/dateHelper";
import {
  getAllRaToProjectThunk,
  createRaToProjectThunk,
  deleteRaToProjectThunk,
  editRaToProjectThunk,
} from "./raToProjectThunk";

const initialState = {
  isLoading: false,
  defaultDate: getCurrentDateString(),
  project: "",
  projectOptions: "",
  raPersonnel: "",
  raPersonnelOptions: "",
  isEditing: false,
  editObjId: "",
  list: [],
  count: 0,
};
export const getAllRaToProject = createAsyncThunk(
  "raToProject/getAllRaToProject",
  getAllRaToProjectThunk
);
export const createRaToProject = createAsyncThunk(
  "raToProject/createRaToProject",
  createRaToProjectThunk
);
export const editRaToProject = createAsyncThunk(
  "raToProject/editRaToProject",
  editRaToProjectThunk
);
export const deleteRaToProject = createAsyncThunk(
  "raToProject/deleteRaToProject",
  deleteRaToProjectThunk
);

const raToProjectSlice = createSlice({
  name: "raToProject",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleDate: (state, { payload: { name, value } }) => {
      //state[name] = Date.parse(value);
      state[name] = new Date(value).toISOString();
      //state[name] = new Date(value).toISOString();
    },
    clearValues: () => {
      //return initialState;
      return {
        ...initialState,
        //jobLocation: getUserFromLocalStorage()?.location || "",
      };
    },
    setEditMode: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRaToProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRaToProject.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload.list;
        state.count = payload.count;
      })
      .addCase(getAllRaToProject.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(createRaToProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createRaToProject.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Project Created");
      })
      .addCase(createRaToProject.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteRaToProject.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteRaToProject.rejected, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(editRaToProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editRaToProject.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Ra To Project Modified...");
      })
      .addCase(editRaToProject.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});
export const {
  handleChange,
  handleDate,
  clearValues,
  setEditMode,
  showLoading,
  hideLoading,
} = raToProjectSlice.actions;
export default raToProjectSlice.reducer;
