import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { clearValues } from "./fundsSlice";
import {
  showLoading,
  hideLoading,
  getAllFunds,
  getAllProjectsWithFunds
} from "../allFunds/allFundsSlice";

export const createFundThunk = async (obj, thunkAPI) => {
  try {
    const resp = await customFetch.post("/projectfunds", obj);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteFundThunk = async (id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/projectfunds/${id}`);
    thunkAPI.dispatch(getAllProjectsWithFunds());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const editFundThunk = async ({ id, obj }, thunkAPI) => {
  try {
    console.log(id, obj);
    const resp = await customFetch.patch(`/projectfunds/${id}`, obj);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
