import React from "react";
import {
  getAllRaToProject,
  setEditMode,
} from "../../features/raToProject/raToProjectSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

const RaToProjectList = () => {
  let counter = 0;
  const dispatch = useDispatch();
  const { list, count, isLoading } = useSelector((store) => store.raToProject);

  useEffect(() => {
    dispatch(getAllRaToProject());
  }, []);

  console.log(list);
  return (
    <>
      <h3>{count} RA Assigned</h3>
      <table className="table table-hover">
        <thead>
          <tr>
            <td>#</td>
            <td>
              <b>Project</b>
            </td>
            <td>
              <b>RA</b>
            </td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => {
            return (
              <tr key={item._id}>
                <td>{(counter += 1)}</td>
                <td>{item?.project?.title}</td>
                <td>{item?.raPersonnel?.name}</td>
                <td>
                  <Button
                    className="btn"
                    component={Link}
                    to="/add-ra"
                    onClick={() =>
                      dispatch(setEditMode({ ...item, editObjId: item._id }))
                    }
                  >
                    Edit
                  </Button>
                  <Button
                    variant="contained"
                    color="warning"
                    component={Link}
                    to="/add-raSalary"
                    state={item}
                  >
                    Add Salary
                  </Button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};

export default RaToProjectList;
