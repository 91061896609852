import { FormRow, FormRowSelect } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import { useSelector, useDispatch } from "react-redux";
import Grid from "@mui/material/Grid";

import {
  handleChange,
  clearValues,
  createRaSalary,
  editRaSalary,
  populateSalary,
} from "../../features/raSalary/raSalarySlice";
import { useLocation } from "react-router-dom";

import GenericQv from "../../components/quickViews/GenericQv";
import { useEffect } from "react";

const AddRaSalary = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { _id, project, raPersonnel } = location.state;
  console.log(location.state);
  const {
    isLoading,
    isEditing,
    editObjId,
    raProjectAssign,
    salaryIncludingTax,
    salary = raPersonnel.monthlySalary,
    itDeduction,
    financialMonth,
    financialMonthOptions,
    financialYear,
    netPayable,
    totalSalary,
    bankAc,
  } = useSelector((store) => store.raSalary);

  const raSalaryObj = {
    raProjectAssign: _id,
    salaryIncludingTax,
    salary,
    itDeduction,
    financialMonth,
    financialYear,
    netPayable,
    totalSalary,
    bankAc,
  };

  useEffect(()=>{
   dispatch(populateSalary(raPersonnel));
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault();

    if (isEditing) {
      dispatch(editRaSalary(raSalaryObj));
      return;
    }
    dispatch(createRaSalary(raSalaryObj));
  };

  const handleObjectInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };

  let projectData = { ...project, qvTitle: "Projectd Details", expand: true };
  let raData = { ...raPersonnel, qvTitle: "Research Assitant Details" };

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? "edit " : "Add "}Research Assistant Salary</h3>

        <Grid container spacing={2}>
          <Grid item xs={6}>
            {raData && <GenericQv dataObject={raData} />}
          </Grid>
          <Grid item xs={6}>
            {project && <GenericQv dataObject={projectData} />}
          </Grid>
        </Grid>
        <div className="form-center">
          <FormRow
            type="text"
            name="bankAc"
            isDisabled
            value={bankAc}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="salary"
            value={salary}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="salaryIncludingTax"
            value={salaryIncludingTax}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="itDeduction"
            value={itDeduction}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="netPayable"
            value={netPayable}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="totalSalary"
            value={totalSalary}
            handleChange={handleObjectInput}
          />

          <FormRowSelect
            name="financialMonth"
            value={financialMonth}
            handleChange={handleObjectInput}
            list={financialMonthOptions}
          />
          <FormRow
            type="text"
            name="financialYear"
            value={financialYear}
            handleChange={handleObjectInput}
          />

          <div className="btn-container">
            <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-block smit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
export default AddRaSalary;
