import { configureStore } from "@reduxjs/toolkit";
import userSlice from "./features/user/userSlice";
import jobSlice from "./features/job/jobSlice";
import allJobsSlice from "./features/allJobs/allJobsSlice";
import allProjectsSlice from "./features/allProjects/allProjectsSlice";
import projectSlice from "./features/project/projectSlice";
import departmentSlice from "./features/departments/departmentSlice";
import allFundsSlice from "./features/allFunds/allFundsSlice";
import fundsSlice from "./features/funds/fundsSlice";
import allRaSlice from "./features/allRa/allRaSlice";
import raSlice from "./features/ra/raSlice";
import allUserSlice from "./features/allUser/allUserSlice";
import userCreateSlice from "./features/userCreate/userCreateSlice";
import raToProjectSlice from "./features/raToProject/raToProjectSlice";
import allRaSalarySlice from "./features/allRaSalary/allRaSalarySlice";
import raSalarySlice from "./features/raSalary/raSalarySlice";

export const store = configureStore({
  reducer: {
    user: userSlice,
    job: jobSlice,
    allJobs: allJobsSlice,
    depts: departmentSlice,
    allProjects: allProjectsSlice,
    project: projectSlice,
    allFunds: allFundsSlice,
    funds: fundsSlice,
    allRa: allRaSlice,
    ra: raSlice,
    allUser: allUserSlice,
    userCreate: userCreateSlice,
    raToProject: raToProjectSlice,
    allRaSalary: allRaSalarySlice,
    raSalary: raSalarySlice
  },
  // middleware: (getDefaultMiddleware) => {
  //  return getDefaultMiddleware({
  //     serializableCheck: {
  //       // Ignore these action types
  //       ignoredActions: ["your/action/type"],
  //       // Ignore these field paths in all actions
  //       ignoredActionPaths: ["meta.arg", "payload.timestamp"],
  //       // Ignore these paths in the state
  //       ignoredPaths: ["items.dates"],
  //     },
  //   });
  // },
});
