import { useDispatch, useSelector } from "react-redux";
import { getAllDepts } from "../features/departments/departmentSlice";
import { useEffect } from "react";

const DepartmentsOption = ({ name, value, handleChange }) => {
  const { depts, count } = useSelector((store) => store.depts);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getAllDepts());
  }, []);

  return (
    <div className="form-row">
      <label htmlFor="depts" className="form-label">
        departments
      </label>
      <select
        name={name}
        id={name}
        value={value}
        onChange={handleChange}
        className="form-select"
      >
        {depts.map((itemValue) => {
          return (
            <option key={itemValue._id} value={itemValue._id}>
              {itemValue.name}
            </option>
          );
        })}
      </select>
    </div>
  );
};
export default DepartmentsOption;
