import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Landing, Error, Register, ProtectedRoute } from "./pages";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Profile,
  AllJobs,
  AddJob,
  Stats,
  SharedLayout,
} from "./pages/dashboard";
import { ProductView } from "./pages/dashboard/ProductView";
import AllProject from "./pages/dashboard/AllProject";
import AddProject from "./pages/dashboard/AddProject";
import AllFunds from "./pages/dashboard/AllFunds";
import AddFunds from "./pages/dashboard/AddFunds";
import AllRa from "./pages/dashboard/AllRa";
import AddRa from "./pages/dashboard/AddRa";
import AllUser from "./pages/dashboard/AllUser";
import AddUser from "./pages/dashboard/AddUser";
import RaToProjectContainer from "./components/raToProject/RaToProjectContainer";
import RaSalaryContainer from "./components/raSalary/RaSalaryContainer";
import AddRaSalary from "./pages/dashboard/AddRaSalary";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path="/"
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route index element={<Stats />} />
          <Route path="all-user" element={<AllUser />} />
          <Route path="add-user" element={<AddUser />} />
          <Route path="all-projects" element={<AllProject />} />
          <Route path="add-project" element={<AddProject />} />
          <Route path="all-funds" element={<AllFunds />} />
          <Route path="add-funds" element={<AddFunds />} />
          <Route path="all-ra" element={<AllRa />} />
          <Route path="add-ra" element={<AddRa />} />
          <Route path="add-raToProject" element={<RaToProjectContainer />} />
          <Route path="all-jobs" element={<AllJobs />} />
          <Route path="all-raSalary" element={<RaSalaryContainer />} />
          <Route path="add-raSalary" element={<AddRaSalary />} />
          <Route path="add-job" element={<AddJob />} />
          <Route path="profile" element={<Profile />} />
          <Route path="products" element={<ProductView />} />
        </Route>
        <Route path="landing" element={<Landing />} />
        <Route path="register" element={<Register />} />
        <Route path="*" element={<Error />} />
      </Routes>
      <ToastContainer position="top-center" />
    </BrowserRouter>
  );
}

export default App;
