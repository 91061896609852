import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { formatDate } from "../../utils/dateHelper";
import { currencyFormatter } from "../../utils/currencyFormatter";
import { useState } from "react";
const _ = require("lodash");

const GenericQv = ({ dataObject }) => {
  var iteration2 = Object.entries(dataObject);

const [expandAll, setExpandAll] = useState(false);



  return (
    <>
      <div>
        {/* <Accordion expanded={dataObject?.expand}> */}
        <Accordion >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <h5>{dataObject.qvTitle}</h5>
          </AccordionSummary>
          <AccordionDetails>
            {iteration2.map((item, index) => {
              return <TypoStyled key={index} name={item} />;
            })}
          </AccordionDetails>
        </Accordion>
      </div>
      <br />
    </>
  );
};
export default GenericQv;

function isValidDate(value) {
  var dateWrapper = new Date(value);
  var getMonth = dateWrapper.getMonth();
  return getMonth > 0 && !isNaN(dateWrapper.getDate());
}

const TypoStyled = (input) => {
  let dataName = "",
    dataValue = "";
  Object.entries(input).forEach(([key, value]) => {
    dataName = value[0];
    if (typeof value[1] === "object") {
      dataValue = value[1]?.name;
    } else {
      dataValue = value[1];
    }
    if (isValidDate(value[1])) {
      dataValue = formatDate(value[1]);
    }
    if (Number.isSafeInteger(value[1])) {
      //console.log(value[1]);
      dataValue = currencyFormatter(value[1]);
    }
  });

  const displayName = _.startCase(dataName);
  if (
    dataName !== "_id" &&
    dataName !== "qvTitle" &&
    dataName !== "__v" &&
    dataName !== "expand"
  ) {
    return (
      <Typography>
        <b>{displayName} : </b> {dataValue}
      </Typography>
    );
  }
};
