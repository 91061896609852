import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { FormRow } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import FormRowDate from "../../components/FormRowDate";
import {
  clearValues,
  handleChange,
  calculateAmount,
  handleDate,
  createFund,
  editFund,
} from "../../features/funds/fundsSlice";
import { useLocation } from "react-router-dom";
import ProjectQuickView from "../../components/quickViews/ProjectQuickView";
import GenericQv from "../../components/quickViews/GenericQv";

const AddFunds = () => {
  const location = useLocation();
  const projectData = location.state;

  const {
    isLoading,
    defaultDate,
    project,
    chequeNumber,
    fundReceived,
    overheadAmount,
    riseAmount,
    piAmount,
    departmentAmount,
    projectAmount,
    fundReceivedDate,
    isEditing,
    editObjId,
  } = useSelector((store) => store.funds);

  const fundObj = {
    project: project ? project : projectData?._id,
    chequeNumber,
    fundReceived,
    overheadAmount,
    riseAmount,
    piAmount,
    departmentAmount,
    projectAmount,
    fundReceivedDate,
  };
  const dispatch = useDispatch();

  const handleObjectInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    if (name === "fundReceived") {
      dispatch(calculateAmount(value));
    }
    dispatch(handleChange({ name, value }));
  };

  const handleDateInput = (name, value) => {
    let modDate = value ? Date.parse(value) : defaultDate;
    dispatch(handleDate({ name, value: modDate }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!chequeNumber || !fundReceived) {
      toast.error("Please fill out all fields");
      return;
    }
    if (isEditing) {
      dispatch(
        editFund({
          id: editObjId,
          obj: fundObj,
        })
      );
      return;
    }
    dispatch(createFund(fundObj));
  };

  let dataObject = { ...projectData, qvTitle: "Project Detail" };

  return (
    <Wrapper>
      <form className="form">
        <h3>{isEditing ? "edit Fund" : "add Fund"}</h3>
        <div>{dataObject && <GenericQv dataObject={dataObject} />}</div>
        <div className="form-center">
          <FormRowDate
            name="fundReceivedDate"
            selected={defaultDate}
            value={fundReceivedDate}
            handleDate={handleDateInput}
          />
          <FormRow
            type="text"
            name="chequeNumber"
            value={chequeNumber}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="fundReceived"
            value={fundReceived}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="overheadAmount"
            value={overheadAmount}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="riseAmount"
            value={riseAmount}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="piAmount"
            value={piAmount}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="departmentAmount"
            value={departmentAmount}
            handleChange={handleObjectInput}
          />
          <FormRow
            type="number"
            name="projectAmount"
            value={projectAmount}
            handleChange={handleObjectInput}
          />
          <div className="btn-container">
            <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-block smit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};
export default AddFunds;
