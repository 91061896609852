import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAllRaThunk } from "./allRaThunk";

const initialState = {
  list: [],
  count: 0,
};

export const getAllRa = createAsyncThunk("allRa", getAllRaThunk);
const allRaSlice = createSlice({
  name: "allRa",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.isLoading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllRa.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllRa.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload.list;
        state.count = payload.count;
      })
      .addCase(getAllRa.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});
export const { showLoading, hideLoading } = allRaSlice.actions;
export default allRaSlice.reducer;
