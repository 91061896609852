import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getCurrentDateString } from "../../utils/dateHelper";
import {
  createProjectThunk,
  editProjectThunk,
  deleteProjectThunk,
} from "./projectThunk";

const initialState = {
  isLoading: false,
  defaultDate: getCurrentDateString(),
  title: "",
  categoryOptions: ["Internal", "External", "MoU"],
  category: "Internal",
  description: "",
  deptInstitute: "644e4429ab5907756e839320",
  piName: "",
  coPiName: "",
  startingDate: getCurrentDateString(),
  endingDate: getCurrentDateString(),
  projectStatusOptions: ["Ongoing", "Submitted", "Upcomming"],
  projectStatus: "Ongoing",
  riseFileTrakerNumber: "",
  isEditing: false,
  editObjId: "",
};

export const createProject = createAsyncThunk(
  "project/createProject",
  createProjectThunk
);
export const deleteProject = createAsyncThunk(
  "project/deleteProject",
  deleteProjectThunk
);
export const editProject = createAsyncThunk(
  "project/editProject",
  editProjectThunk
);

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    handleDate: (state, { payload: { name, value } }) => {
      //state[name] = Date.parse(value);
      state[name] = new Date(value).toISOString();
      //state[name] = new Date(value).toISOString();
    },
    clearValues: () => {
      //return initialState;
      return {
        ...initialState,
        //jobLocation: getUserFromLocalStorage()?.location || "",
      };
    },
    setEditMode: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createProject.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Project Created");
      })
      .addCase(createProject.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteProject.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteProject.rejected, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(editProject.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editProject.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Project Modified...");
      })
      .addCase(editProject.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});
export const { handleChange, handleDate, clearValues, setEditMode } =
  projectSlice.actions;
export default projectSlice.reducer;
