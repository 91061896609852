import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { toast } from "react-toastify";

const initialState = {
  count: 0,
  depts: [],
};

export const getAllDepts = createAsyncThunk(
  "departments/getAllDepts",
  async (_, thunkAPI) => {
    try {

      const existData = thunkAPI.getState().depts.count >0;
      if(!existData) {
        const resp = await customFetch.get("/depts");
        return resp.data;
      }
      return {
        departments: thunkAPI.getState().depts.depts,
        count: thunkAPI.getState().depts.count,
      };
    } catch (error) {
      return checkForUnauthorizedResponse(error, thunkAPI);
    }
  }
);

const deptartmentsSlice = createSlice({
  name: "departments",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllDepts.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllDepts.fulfilled, (state, { payload }) => {
        console.log(payload);
        state.isLoading = false;
        state.depts = payload.departments;
        state.count = payload.count;
      })
      .addCase(getAllDepts.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});
export const { hideLoading, showLoading } = deptartmentsSlice.actions;
export default deptartmentsSlice.reducer;
