import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAllFundsThunk, getAllProjectWithFundsThunk } from "./allFundsThunk";

const initialState = {
  isLoading: false,
  list: [],
  count: 0,
};

export const getAllFunds = createAsyncThunk(
  "allFunds",
  getAllFundsThunk
);
export const getAllProjectsWithFunds = createAsyncThunk(
  "allProjectWithFunds",
  getAllProjectWithFundsThunk
);

const allFundsSlice = createSlice({
  name: "allProject",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllFunds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllFunds.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload.list;
        state.count = payload.count;
      })
      .addCase(getAllFunds.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(getAllProjectsWithFunds.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProjectsWithFunds.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload.list;
        state.count = payload.count;
      })
      .addCase(getAllProjectsWithFunds.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { showLoading, hideLoading } = allFundsSlice.actions;
export default allFundsSlice.reducer;
