import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import {
  createUserThunk,
  deleteUserThunk,
  editUserThunk,
  updateVerificationThunk,
} from "./userCreateThunk";

const initialState = {
  isLoading: false,
  isEditing: false,
  editObjId: "",
  name: "",
  email: "",
  password: "",
  _id: "",
  isVerified: false,
};
export const createUser = createAsyncThunk(
  "createUser/createUser",
  createUserThunk
);

export const deleteUser = createAsyncThunk(
  "createUser/deleteUser",
  deleteUserThunk
);

export const editUser = createAsyncThunk("createUser/editUser", editUserThunk);
export const updateVerification = createAsyncThunk(
  "createUser/updateVerification",
  updateVerificationThunk
);

const userCreateSlice = createSlice({
  name: "userCreate",
  initialState,
  reducers: {
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    clearValues: () => {
      return {
        ...initialState,
      };
    },
    setEditMode: (state, { payload }) => {
      return { ...state, isEditing: true, ...payload };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createUser.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("User Created");
      })
      .addCase(createUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(deleteUser.fulfilled, (state, { payload }) => {
        toast.success(payload);
      })
      .addCase(deleteUser.rejected, (state, { payload }) => {
        toast.error(payload);
      })
      .addCase(editUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(editUser.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("User Updated...");
      })
      .addCase(editUser.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(updateVerification.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateVerification.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("User Updated...");
      })
      .addCase(updateVerification.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { handleChange, clearValues, setEditMode } =
  userCreateSlice.actions;
export default userCreateSlice.reducer;
