import { FormRow, FormRowSelect } from "../../components";
import Wrapper from "../../assets/wrappers/DashboardFormPage";
import { useSelector, useDispatch } from "react-redux";

import {
  handleChange,
  clearValues,
  createRa,
  editRa,
  handleDate,
} from "../../features/ra/raSlice";
import FormRowDate from "../../components/FormRowDate";

const AddRa = () => {
  const {
    name,
    isLoading,
    defaultDate,
    isEditing,
    designationOptions,
    categoryOtions,
    bankAc,
    monthlySalary,
    category,
    designation,
    project,
    editObjId,
    dateJoined,
    dateTermination,
  } = useSelector((store) => store.ra);
  const { user } = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const handleSubmit = (e) => {
    e.preventDefault();
    // if (!bankAc || !monthlySalary || !designation) {
    //   toast.error("Please fill out all fields");
    //   return;
    // }
    if (isEditing) {
      console.log(editObjId);
      dispatch(
        editRa({
          _id: editObjId,
          ra: {
            name,
            bankAc,
            monthlySalary,
            category,
            designation,
            project,
            dateJoined,
            dateTermination,
          },
        })
      );
      return;
    }
    dispatch(
      createRa({
        name,
        bankAc,
        monthlySalary,
        category,
        designation,
        project,
        dateJoined,
        dateTermination,
      })
    );
  };
  const handleJobInput = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    dispatch(handleChange({ name, value }));
  };
  const handleDateInput = (name, value) => {
    let modDate = value ? Date.parse(value) : defaultDate;
    dispatch(handleDate({ name, value: modDate }));
  };
  return (
    <Wrapper>
      <form className="form">
        <h3>
          {isEditing ? "edit Research Assistant" : "Add Research Assistant"}
        </h3>
        <div className="form-center">
          <FormRow
            type="text"
            name="name"
            value={name}
            handleChange={handleJobInput}
          />
          <FormRow
            type="text"
            name="bankAc"
            value={bankAc}
            handleChange={handleJobInput}
          />
          <FormRow
            type="text"
            name="monthlySalary"
            value={monthlySalary}
            handleChange={handleJobInput}
          />
          <FormRowSelect
            name="designation"
            value={designation}
            handleChange={handleJobInput}
            list={designationOptions}
          />
          <FormRowSelect
            name="category"
            value={category}
            handleChange={handleJobInput}
            list={categoryOtions}
          />
          <FormRowDate
            name="dateJoined"
            selected={defaultDate}
            value={dateJoined}
            handleDate={handleDateInput}
          />
          <FormRowDate
            name="dateTermination"
            selected={defaultDate}
            value={dateTermination}
            handleDate={handleDateInput}
          />

          <div className="btn-container">
            <button
              type="button"
              className="btn btn-block clear-btn"
              onClick={() => dispatch(clearValues())}
            >
              Clear
            </button>
            <button
              type="submit"
              className="btn btn-block smit-btn"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              Submit
            </button>
          </div>
        </div>
      </form>
    </Wrapper>
  );
};

export default AddRa;
