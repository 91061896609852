import { useDispatch } from "react-redux";
import { formatDate } from "../../utils/dateHelper";
import { setEditMode } from "../../features/ra/raSlice";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const RaList = ({ list }) => {
  const dispatch = useDispatch();
  return (
    <>
      <table className="table table-hover">
        <thead>
          <tr>
            <td>Name</td>
            <td>Bank Account No.</td>
            <td>Designation</td>
            <td>Monthly Salary</td>
            <td>Date Joined</td>
            <td></td>
          </tr>
        </thead>
        <tbody>
          {list.map((item) => {
            return (
              <tr key={item._id}>
                <td>{item.name}</td>
                <td>{item.bankAc}</td>
                <td>{item.designation}</td>
                <td>{item.monthlySalary}</td>
                <td>{formatDate(item.dateJoined)}</td>
                <td>
                  <Button
                    className="btn"
                    component={Link}
                    to="/add-ra"
                    onClick={() =>
                      dispatch(setEditMode({ ...item, editObjId: item._id }))
                    }
                  >
                    Edit
                  </Button>
                 
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </>
  );
};
export default RaList;
