import { FaLocationArrow, FaBriefcase, FaCalendarAlt } from "react-icons/fa";
import Wrapper from "../assets/wrappers/Job";
import JobInfo from "./JobInfo";
import moment from "moment";

export const Product = ({
  _id,
  company,
  createdAt,
  description,
  name,
  image,
  price,
}) => {
  const date = moment(createdAt).format("MMM Do, YYYY");
  return (
    <Wrapper>
      <header>
        <div className="main-icon">{company.charAt(0)}</div>
        <div className="inf">
          <h5>{name}</h5>
          <p>
            {company}, Price: ${price}
          </p>
        </div>
      </header>
      <div className="content">
        <div className="content-center">
          <JobInfo icon={<FaLocationArrow />} text={description} />
          <JobInfo icon={<FaCalendarAlt />} text={date} />
        </div>
        <footer>
          <div className="actions"></div>
        </footer>
      </div>
    </Wrapper>
  );
};
