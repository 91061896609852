import Wrapper from "../../assets/wrappers/JobsContainer";
import Loading from "../Loading";
import { useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import { getAllRa } from "../../features/allRa/allRaSlice";
import { setEditMode } from "../../features/ra/raSlice";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import RaList from "./RaList";
import Paper from "@mui/material/Paper";

const RaContainer = () => {
  const dispatch = useDispatch();
  const { list, count, isLoading } = useSelector((store) => store.allRa);
  useEffect(() => {
    dispatch(getAllRa());
  }, []);

  if (isLoading) {
    return <Loading center />;
  }
  return (
    <Wrapper>
      <h3>
        {count} Research Assistant{count && "s "}
        <Button variant="contained" component={Link} to="/add-ra">
          Add RA
        </Button>
      </h3>
      <Paper elevation={2}>
        <RaList list={list} />
      </Paper>
    </Wrapper>
  );
};

export default RaContainer;
