import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";

export const getAllFundsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get("/projectfunds");
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const getAllProjectWithFundsThunk = async (_, thunkAPI) => {
  try {
    const resp = await customFetch.get("/projectfunds/ProjectWithFunds");
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
