import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

const FormRowDate = ({ labelText, name, value, handleDate }) => {
  return (
    <div className="form-row">
      <label htmlFor={name} className="form-label">
        {labelText || name}
      </label>
      <DatePicker
        className="form-date"
        selected={value}
        closeCalendar={false}
        value={value}
        onChange={(value) => handleDate(name, value)}
      />
    </div>
  );
};
export default FormRowDate;
