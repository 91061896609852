import { useDispatch, useSelector } from "react-redux";
import { getAllProducts } from "../features/products/productSlice";
import Wrapper from "../assets/wrappers/JobsContainer";
import { useEffect } from "react";
import { Product } from "./Product";
import Loading from "./Loading";

const ProductContainer = () => {
  const dispatch = useDispatch();
  const { isLoading, products, count } = useSelector((store) => store.products);

  useEffect(() => {
    dispatch(getAllProducts());

  }, []);

  if (isLoading) {
    return <Loading center />;
  }

  return (
    <Wrapper>
      <div className="jobs">
        <h3>{count} products found</h3>
        {products.map((product) => {
          return <Product key={product._id} {...product} />;
        })}
      </div>
    </Wrapper>
  );
};
export default ProductContainer;
