import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { getAllProjectThunk, showStatThunk } from "./allProjectThunk";

const initialState = {
  isLoading: true,
  list: [],
  count: 0,
  stats: {},
  monthlyApplications: [],
};

export const getAllProjects = createAsyncThunk("allProjects", getAllProjectThunk);
export const showStats = createAsyncThunk("allProjects/showStats", showStatThunk);


const allProjectsSlice = createSlice({
  name: "allProject",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state) => {
      state.isLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllProjects.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllProjects.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.list = payload.list;
        state.count = payload.count;
      })
      .addCase(getAllProjects.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      })
      .addCase(showStats.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(showStats.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.stats = payload.defaultStats;
        state.monthlyApplications = payload.monthlyApplications;
      })
      .addCase(showStats.rejected, (state, { payload }) => {
        state.isLoading = false;
        toast.error(payload);
      });
  },
});

export const { showLoading, hideLoading } = allProjectsSlice.actions;
export default allProjectsSlice.reducer;
