import { useDispatch, useSelector } from "react-redux";
import { getAllProjects } from "../../features/allProjects/allProjectsSlice";
import Wrapper from "../../assets/wrappers/JobsContainer";
import { useEffect } from "react";
import Loading from "../Loading";
import ProjectSec from "./ProjectSec";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";

const ProjectContainer = () => {
  const dispacth = useDispatch();
  const { isLoading, list, count } = useSelector((store) => store.allProjects);

  useEffect(() => {
    dispacth(getAllProjects());
  }, []);

  if (isLoading) {
    return <Loading center />;
  }

  return (
    <Wrapper>
      <h3>
        {count} project{count && "s "}
        <Button variant="contained" component={Link} to="/add-project">
          Add Project
        </Button>
      </h3>
      <ProjectSec projectList={list} />
    </Wrapper>
  );
};
export default ProjectContainer;
