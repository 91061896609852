import { showLoading, hideLoading, getAllUsers } from "../allUser/allUserSlice";
import customFetch, { checkForUnauthorizedResponse } from "../../utils/axios";
import { clearValues } from "./userCreateSlice";

export const createUserThunk = async (obj, thunkAPI) => {
  try {
    const resp = await customFetch.post("/users", obj);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const deleteUserThunk = async (_id, thunkAPI) => {
  thunkAPI.dispatch(showLoading());
  try {
    const resp = await customFetch.delete(`/users/${_id}`);
    thunkAPI.dispatch(getAllUsers());
    return resp.data.msg;
  } catch (error) {
    thunkAPI.dispatch(hideLoading());
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const editUserThunk = async ( obj , thunkAPI) => {
  try {
    const resp = await customFetch.patch(`/users/updateUser`, obj);
    thunkAPI.dispatch(clearValues());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};

export const updateVerificationThunk = async (obj, thunkAPI) => {
  console.log(obj);
  try {
    const resp = await customFetch.patch(`/users/updateVerification`, obj);
     thunkAPI.dispatch(getAllUsers());
    return resp.data;
  } catch (error) {
    return checkForUnauthorizedResponse(error, thunkAPI);
  }
};
